import { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { AxiosResponse } from 'axios'
import { NavRoute } from 'types/custom-types'

import {
  STATUS_APPROVED,
  STATUS_DENIED,
  STATUS_DIRECTION_EDITING,
  STATUS_EDIT_DIRECTION,
  STATUS_ENLISTED,
  STATUS_SEND_EDITING,
  STATUS_USER_EDIT,
} from '@common/constants'
import useProfileStatements from '@common/routes/useProfileStatements'
import terms from '@common/terms'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import {
  findFirstInvalidRoute,
  findNextRoute,
  getFilteredRoutes,
  getRouteStatus,
  validateRoutes,
} from '@lib/routes'
import { FillingStatementsSectionKey } from '@models'
import {
  createToast,
  getDirectionsListOptions,
  getDirectionsListStatements,
} from '@redux/action-types'
import {
  selectBlankStatement,
  selectDirectionListOptions,
  selectDirectionsListStatements,
  selectLevelValue,
  selectStatementsElement,
  selectTrajectoryValue,
} from '@redux/actions/selectors'
import api, {
  CheckStatementStatusOk,
  CheckStatementStatusResponse,
} from '@services/api'

type Route = NavRoute<FillingStatementsSectionKey>

const useFillingStatementsProvideRoutes = (
  initialRoutes: Route[] = [],
  scenarioKeys
) => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useAppDispatch()
  const currentRoute = useRef<string>()
  const visitedRoutesPaths = useRef<string[]>([])
  const [routes, setRoutes] = useState<Route[]>(initialRoutes || [])
  const [allFieldsFilled, setAllFieldsFilled] = useState<boolean>(true)
  const { APPLICATION_SECTION_ROUTE } = useProfileStatements()

  const statement = useAppSelector(selectDirectionsListStatements)

  useEffect(() => {
    dispatch(getDirectionsListStatements(Number(statementId)))
  }, [])

  useEffect(() => {
    setRoutes((prev) =>
      prev.map((route) => {
        if (route.key === 'examResults') {
          return { ...route, title: initialRoutes[0].title }
        }
        return { ...route }
      })
    )
  }, [initialRoutes[0].title])

  const achievementsRoute = initialRoutes.find(
    (route) => route.key === 'achievements'
  )

  useEffect(() => {
    setRoutes((prev) =>
      prev.map((route) => {
        if (route.key === 'achievements') {
          return { ...route, text: achievementsRoute?.text || '' }
        }
        return { ...route }
      })
    )
  }, [achievementsRoute?.text])

  const initialRoutesPathnames = useRef(initialRoutes?.map((r) => r.to))

  const [lastBtn, setBtn] = useState<string>('')
  const [checkboxses, setValue] = useState<number[]>([])

  const { visitedRoutes, unvisitedRoutes, visibleRoutes, visitedSortedRoutes } =
    getFilteredRoutes(routes, currentRoute.current)

  const level = useAppSelector(selectLevelValue)
  const trajectory = useAppSelector(selectTrajectoryValue)
  const blankStatements = useAppSelector(selectBlankStatement)
  const statementEl = useAppSelector(selectStatementsElement)

  const benefitsAndSpecialConditions = initialRoutes.find(
    (route) => route.key === 'benefitsAndSpecialConditions'
  )
  useEffect(() => {
    setRoutes((prev) =>
      prev.map((route) => {
        if (
          route.key === 'benefitsAndSpecialConditions' &&
          statementEl?.paymentType === 2
        ) {
          return {
            ...route,
            title: 'Специальные условия для прохождения вступительных испытаний',
          }
        }
        return { ...route }
      })
    )
  }, [statementEl?.paymentType])

  useEffect(() => {
    if (blankStatements) {
      setValue(blankStatements.checkbox.map((el) => el.id) || [])
    }
  }, [blankStatements])

  const approved =
    (statementEl &&
      !statementEl.error &&
      Number(statementEl.statusStatement.code) >= STATUS_APPROVED &&
      Number(statementEl.statusStatement.code) !== STATUS_SEND_EDITING &&
      Number(statementEl.statusStatement.code) !== STATUS_USER_EDIT) ||
    false

  let type: {
    certificate?: boolean
    achievements?: boolean
    privileges?: boolean
    contract?: boolean
    target?: boolean
    examResults?: boolean
    test?: boolean
    benefitsAndSpecialConditions?: boolean
    directionsPriority?: boolean
    conclusionBaseDepartment?: boolean
    directionTrainings?: boolean
  } = {
    contract: approved,
    test: approved,
    benefitsAndSpecialConditions: false,
  }

  const levelsWithPrivileges = [
    scenarioKeys.magistra_rf.tdId,
    scenarioKeys.level_training_bachelor_ino.tdId,
  ] //[2, 7]
  const levelsWithEge = [
    scenarioKeys.level_training_bachelor.tdId,
    scenarioKeys.quota.tdId,
    scenarioKeys.level_training_bachelor_ino.tdId,
    scenarioKeys.level_training_spo.tdId,
    scenarioKeys.level_training_bachelor_tashkent.tdId,
  ] //[1, 4, 7, 8, 9]

  const scenarioId = level.scenarioId ? level.scenarioId : level.id

  const currentDate = new Date()
  const endDate1 = new Date('2024-08-20T23:59:00+03:00')
  const endDate2 = new Date('2024-08-21T23:59:00+03:00')

  useEffect(() => {
    type.certificate = false
    type.examResults = true
    type.directionTrainings = false
    if (
      trajectory.id &&
      (level.scenarioId == undefined ? level.id : level.scenarioId)
    ) {
      if (
        trajectory.id !== 1 ||
        !levelsWithPrivileges.includes(
          level.scenarioId == undefined ? level.id : level.scenarioId
        )
      ) {
        type.privileges = false
      }

      if (
        trajectory.id !== 1 &&
        !levelsWithEge.includes(
          level.scenarioId == undefined ? level.id : level.scenarioId
        )
      ) {
        type.examResults = false
      }

      if (trajectory.id !== 1) {
        type.target = false
        type.contract = false
      }

      if (trajectory.id === 1) {
        //Код до переделки базы////////
        //
        // if (level.id === LEVEL_TRAINING_MASTER) {
        //   type = {
        //     achievements: true,
        //     privileges: false,
        //     target: true,
        //     examResults: false,
        //     contract: approved,
        //     test: approved,
        //   }
        // }
        // if (
        //   level.id === LEVEL_TRAINING_BACHELOR ||
        //   level.id === LEVEL_TRAINING_BACHELOR_VIB ||
        //   level.id === LEVEL_TRAINING_BACHELOR_DAG ||
        //   level.id === LEVEL_TRAINING_BACHELOR_VOL
        // ) {
        //   type = {
        //     examResults: true,
        //     achievements: true,
        //     privileges: true,
        //     target: true,
        //     contract: approved,
        //     test: approved,
        //   }
        // }

        let show
        if (
          (level.scenarioId == undefined
            ? level.id === scenarioKeys.asperatnuta_rf.tdId
            : level.scenarioId === scenarioKeys.asperatnuta_rf.tdId) ||
          (level.scenarioId == undefined
            ? level.id === scenarioKeys.magistra_ino.tdId
            : level.scenarioId === scenarioKeys.magistra_ino.tdId) ||
          (level.scenarioId == undefined
            ? level.id === scenarioKeys.magistra_rf.tdId
            : level.scenarioId === scenarioKeys.magistra_rf.tdId) ||
          (level.scenarioId == undefined
            ? level.id === scenarioKeys.magistra_tashkent.tdId
            : level.scenarioId === scenarioKeys.magistra_tashkent.tdId)
        ) {
          show = true
        } else {
          show = false
        }
        //level.id здесь на самом деле id сценария, не понимаю, почему пишут про уровень обучения
        //ввиду разделения на бюджет\контракт level.id теперь просто индекс, смотреть необходимо на scenarioId
        if (
          (level.scenarioId == undefined
            ? level.id === scenarioKeys.level_training_bachelor.tdId
            : level.scenarioId === scenarioKeys.level_training_bachelor.tdId) ||
          (level.scenarioId == undefined
            ? level.id != scenarioKeys.level_training_bachelor_ino.tdId
            : level.scenarioId != scenarioKeys.level_training_bachelor_ino.tdId)
        ) {
          type = {
            achievements: true,
            privileges: !show,
            target: true,
            examResults: true,
            contract: approved,
            test: approved,
            benefitsAndSpecialConditions: show,
          }
        }
      }

      if (trajectory.id === 2) {
        type.achievements = false
      }

      if (
        level.scenarioId == undefined
          ? level.id === scenarioKeys.level_training_preparation.tdId
          : level.scenarioId === scenarioKeys.level_training_preparation.tdId
      ) {
        type.directionTrainings = true
      } else if (
        level.scenarioId == undefined
          ? level.id === scenarioKeys.asperatnuta_rf.tdId
          : level.scenarioId === scenarioKeys.asperatnuta_rf.tdId
      ) {
        type.directionTrainings = true
      } else {
        type.directionTrainings = false
      }

      if (
        trajectory.id === 2 && level.scenarioId == undefined
          ? level.id === scenarioKeys.level_training_bachelor_tashkent.tdId
          : level.scenarioId === scenarioKeys.level_training_bachelor_tashkent.tdId
      ) {
        type.examResults = false
      }

      if (trajectory.id === 3) {
        type = {
          achievements: false,
          privileges: false,
          target: false,
          examResults: false,
          contract: false,
          test: false,
          benefitsAndSpecialConditions: false,
        }
      }

      if (
        (trajectory.id === 2 || trajectory.id === 1) &&
        (level.scenarioId == undefined
          ? level.id === scenarioKeys.level_training_spo.tdId
          : level.scenarioId === scenarioKeys.level_training_spo.tdId)
      ) {
        type.target = false
        type.examResults = false
        type.privileges = false
        type.test = false
        type.achievements = true
        type.contract = false
        type.certificate = true

        if (trajectory.id === 1) {
          const code = Number(statementEl?.statusStatement?.code)

          if (
            code === STATUS_APPROVED ||
            code === STATUS_EDIT_DIRECTION ||
            code === STATUS_DIRECTION_EDITING ||
            code === STATUS_DENIED ||
            code === STATUS_ENLISTED
          ) {
            type.test = true
          }
        }
      }

      /** Траектория поступления «Поступаю как гражданин РФ» */
      const TRAJECTORY_AS_RUSSIA_ID = 1
      const isLevelTrainingSPO =
        level.scenarioId == undefined
          ? level.id === scenarioKeys.level_training_spo.tdId
          : level.scenarioId === scenarioKeys.level_training_spo.tdId
      const paymentTypeIsBudgetID = 1

      if (
        trajectory.id === TRAJECTORY_AS_RUSSIA_ID &&
        isLevelTrainingSPO &&
        statementEl?.paymentType === paymentTypeIsBudgetID
      ) {
        type.privileges = true
      }

      if (
        level.scenarioId == undefined
          ? level.id !== scenarioKeys.level_training_spo.tdId
          : level.scenarioId !== scenarioKeys.level_training_spo.tdId
      ) {
        type.certificate = false
      }

      if (trajectory.id === 1 && statementEl?.paymentType === 2) {
        type.target = false
      }

      if (
        trajectory.id === 1 &&
        statementEl?.paymentType === 1 &&
        (scenarioId === scenarioKeys.level_training_bachelor_ino.tdId ||
          scenarioId === scenarioKeys.magistra_rf.tdId)
      ) {
        type.contract = false
      }

      // РФ +Контракт или ИНО и accessConclusion
      if (
        (trajectory?.id === 1 || trajectory?.id === 2) &&
        statement &&
        statement.find((item) => item.competitiveGroupStatement?.accessConclusion)
      ) {
        type.contract = true
      }

      if (
        level.scenarioId == undefined
          ? level.id === scenarioKeys.level_training_preparation.tdId
          : level.scenarioId === scenarioKeys.level_training_preparation.tdId
      ) {
        type.benefitsAndSpecialConditions = true
        type.privileges = false
        type.target = true
        type.achievements = true
      }

      if (
        level.scenarioId == undefined
          ? level.id === scenarioKeys.asperatnuta_rf.tdId
          : level.scenarioId === scenarioKeys.asperatnuta_rf.tdId
      ) {
        type.conclusionBaseDepartment = false
        type.achievements = true
        type.benefitsAndSpecialConditions = true
      } else {
        type.conclusionBaseDepartment = false
      }

      if (
        level.scenarioId == undefined
          ? level.id === scenarioKeys.quota.tdId
          : level.scenarioId === scenarioKeys.quota.tdId
      ) {
        type.conclusionBaseDepartment = false
        type.directionTrainings = false
      }

      if (statement?.every((s) => !s.competitiveGroupStatement.accessConclusion)) {
        type.contract = false
      }

      type.directionsPriority = statementEl?.statusStatementId === STATUS_APPROVED

      if (
        statementEl?.paymentType === 1 &&
        statementEl?.trajectoryId === 1 &&
        statementEl?.scenarioId === scenarioKeys.level_training_bachelor_ino.tdId
      ) {
        type.directionsPriority = false
      }

      if (
        (statementEl?.scenarioId === scenarioKeys.level_training_bachelor.tdId ||
          statementEl?.scenarioId ===
            scenarioKeys.level_training_bachelor_ino.tdId) &&
        currentDate > endDate1
      ) {
        type.directionsPriority = false
      }

      if (
        statementEl?.scenario?.code === scenarioKeys.magistra_rf.onesKey &&
        currentDate > endDate2
      ) {
        type.directionsPriority = false
      }

      if (
        (level.scenarioId == undefined
          ? level.id === scenarioKeys.asperatnuta_rf.tdId
          : level.scenarioId === scenarioKeys.asperatnuta_rf.tdId) ||
        (level.scenarioId == undefined
          ? level.id === scenarioKeys.magistra_ino.tdId
          : level.scenarioId === scenarioKeys.magistra_ino.tdId) ||
        (level.scenarioId == undefined
          ? level.id === scenarioKeys.magistra_rf.tdId
          : level.scenarioId === scenarioKeys.magistra_rf.tdId) ||
        (level.scenarioId == undefined
          ? level.id === scenarioKeys.magistra_tashkent.tdId
          : level.scenarioId === scenarioKeys.magistra_tashkent.tdId)
      ) {
        type.examResults = false
      }
      changeRouteVisibility(type)
    }
  }, [trajectory.id, scenarioId, approved, statementEl?.paymentType, statement])
  const { statementId } = useParams()

  const onNextPageNavigate = async (selectedCheckboxes: number[] = []) => {
    const route = findNextRoute(visibleRoutes, String(currentRoute.current))

    if (route) {
      return navigate(route.to)
    }

    const checkboxes = selectedCheckboxes.length ? selectedCheckboxes : checkboxses

    try {
      const response = await api.client.statementReview(
        Number(statementId),
        checkboxes
      )
      const { success, message } = response.data
      if (success) {
        dispatch(
          createToast(
            'Уважаемый поступающий! Ваше заявление отправлено на проверку в приемную комиссию. В течение пяти рабочих дней ожидайте информацию в личном кабинете с результатами проверки',
            'success'
          )
        )
        navigate(APPLICATION_SECTION_ROUTE)
      } else {
        dispatch(createToast(message.blank ? message.blank : '', 'danger'))
      }
    } catch (response) {
      const { data } = response as AxiosResponse
      const { message } = data as Exclude<
        CheckStatementStatusResponse,
        CheckStatementStatusOk
      >
      dispatch(
        createToast(data.message.restrict ? data.message.restrict : '', 'danger')
      )
      const validatedRoutes = validateRoutes<FillingStatementsSectionKey>(
        visibleRoutes,
        Object.keys(message) as FillingStatementsSectionKey[],
        currentRoute.current
      )
      setRoutes(validatedRoutes)
      const route = findFirstInvalidRoute(validatedRoutes)
      route && navigate(route.to)
    }
  }

  const changeRouteVisibility = (params: { [key in Route['key']]?: boolean }) => {
    const keys = Object.keys(params)
    setRoutes((prev) =>
      prev.map((route) => {
        if (keys.includes(route.key)) {
          if (
            level.scenarioId == undefined
              ? level.id === scenarioKeys.quota.tdId
              : level.scenarioId === scenarioKeys.quota.tdId
          ) {
            params.conclusionBaseDepartment = false
          } else if (
            level.scenarioId == undefined
              ? level.id === scenarioKeys.level_training_bachelor_ino.tdId
              : level.scenarioId === scenarioKeys.level_training_bachelor_ino.tdId
          ) {
            params.conclusionBaseDepartment = false
          } else if (
            level.scenarioId == undefined
              ? level.id === scenarioKeys.magistra_ino.tdId
              : level.scenarioId === scenarioKeys.magistra_ino.tdId
          ) {
            params.conclusionBaseDepartment = false
          } else if (
            level.scenarioId == undefined
              ? level.id === scenarioKeys.magistra_rf.tdId
              : level.scenarioId === scenarioKeys.magistra_rf.tdId
          ) {
            params.conclusionBaseDepartment = false
          } else if (
            level.scenarioId == undefined
              ? level.id === scenarioKeys.level_training_bachelor_tashkent.tdId
              : level.scenarioId ===
                scenarioKeys.level_training_bachelor_tashkent.tdId
          ) {
            params.conclusionBaseDepartment = false
          } else if (
            level.scenarioId == undefined
              ? level.id === scenarioKeys.magistra_tashkent.tdId
              : level.scenarioId === scenarioKeys.magistra_tashkent.tdId
          ) {
            params.conclusionBaseDepartment = false
          } else {
            params.conclusionBaseDepartment = false
          }
          return { ...route, hidden: !params[route.key] }
        }
        return { ...route }
      })
    )
  }

  const updateRoutesStatuses = (invaidKeys: FillingStatementsSectionKey[]) => {
    setRoutes((prev) =>
      prev.map((route) => ({
        ...route,
        status: getRouteStatus(
          invaidKeys,
          visitedRoutesPaths.current,
          route,
          currentRoute.current
        ),
      }))
    )
  }

  const checkFillingAnketa = async (checkboxes?: { checkboxes?: number[] }) => {
    const response = await api.client.checkFillingAnketa(
      Number(statementId),
      checkboxes
    )
    const data = response.data
    return data
  }

  const checkStatementStatus = async () => {
    try {
      const { success } = await checkFillingAnketa()
      if (success) {
        updateRoutesStatuses([])
        setAllFieldsFilled(true)
      }
    } catch (response) {
      const { data } = response as AxiosResponse
      const { message, success } = data as Exclude<
        CheckStatementStatusResponse,
        CheckStatementStatusOk
      >
      if (!success) {
        updateRoutesStatuses(Object.keys(message) as FillingStatementsSectionKey[])
        if (Object.keys(message).length === 1 && message.blank) {
          setAllFieldsFilled(true)
          return
        }
        setAllFieldsFilled(false)
      }
    }
  }

  useEffect(() => {
    const { pathname } = location
    const key = pathname.split('/filling-statements/')[1]

    if (key === 'blank') {
      setBtn(terms.SEND_APPLICATION)
    } else {
      setBtn('')
    }

    if (key && !visibleRoutes.find((route) => key.includes(route.to))) {
      navigate(visibleRoutes[0].to)
    }

    if (!initialRoutesPathnames.current.includes(key)) return

    if (!visitedRoutesPaths.current.includes(key)) {
      visitedRoutesPaths.current.push(key)
    }

    if (key !== currentRoute.current) {
      currentRoute.current = key
      checkStatementStatus()
    }
  }, [location.pathname, checkStatementStatus])

  return {
    lastBtn,
    routes: visibleRoutes,
    visitedRoutes,
    unvisitedRoutes,
    onNextPageNavigate,
    visitedSortedRoutes,
    checkFillingAnketa,
    allFieldsFilled,
    checkboxses,
  }
}

export default useFillingStatementsProvideRoutes
