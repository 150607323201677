import React from 'react'

import terms from '@common/terms'
import LevelListElement from '@components/LevelStatementsList/LevelListElement'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import { setLevelValue } from '@redux/action-types'
import { selectLevelList, selectLevelValue } from '@redux/actions/selectors'

const LevelStatementsList = () => {
  const active = useAppSelector(selectLevelValue)

  const dispatch = useAppDispatch()

  const setActive = (value) => {
    dispatch(setLevelValue(value))
  }

  const levelDataList = useAppSelector(selectLevelList)

  const renderLevelList = () => {
    const countMainBlocks = levelDataList?.reduce(
      (acc, { name }) =>
        name.includes(terms.MASTER_PROGRAM) ||
        name.includes(terms.SPECIALIST_PROGRAM)
          ? acc + 1
          : acc,
      0
    )

    switch (countMainBlocks) {
      case 1:
        return levelDataList?.map(({ id, name }, index) => (
          <LevelListElement
            key={index}
            setActive={setActive}
            active={id === active.id}
            id={id}
            name={name}
          />
        ))
      case 2:
        return levelDataList?.map(({ id, name }, index) => (
          <LevelListElement
            key={index}
            setActive={setActive}
            active={id === active.id}
            id={id}
            name={name}
          />
        ))
      default:
        return levelDataList?.map(({ id, name }, index) => (
          <LevelListElement
            key={index}
            setActive={setActive}
            active={id === active.id}
            id={id}
            name={name}
          />
        ))
    }
  }

  return <section className="level-list">{renderLevelList()}</section>
}

export default LevelStatementsList
